import { render, staticRenderFns } from "./TeamMember.vue?vue&type=template&id=5d56396b&scoped=true"
import script from "./TeamMember.vue?vue&type=script&lang=js"
export * from "./TeamMember.vue?vue&type=script&lang=js"
import style0 from "./TeamMember.vue?vue&type=style&index=0&id=5d56396b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-872774970/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d56396b",
  null
  
)

export default component.exports